/* eslint-disable no-bitwise */
/* eslint-disable quote-props */
import { Injectable } from "@angular/core";
// eslint-disable-next-line max-len
import { ProjectStatus, TimeUnit, WeekdayFC, ContactType, TerminalMediaType, AbsenceCreditAccountType, AbsenceVerificationType, AbsencePaymentType, CheckTimeRounding, TerminalCheckinMode, ScheduledTaskStatus, TerminalTrackingEvents, WorkHoursType, OpeningTimeType, VisibilityType, VisibilityTarget, VisibilityTargetLeader, VisibilityName, DayOfWeek, ShiftFailureReason, CheckinStatus, SurchargeValueType } from "@vierkant-software/types__api";
import { DateTime } from "luxon";
import { AppService } from "src/services/app.service";
import { formatDateTime } from "src/util/formatting/date";
import { formatTime } from "src/util/formatting/time";
import { formatUser } from "src/util/formatting/user";
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

// #region helpers
function readonly(proto: unknown, key: string | number | symbol) {
    Object.freeze((<Record<string | number | symbol, unknown>>proto)[key]);
    Object.seal((<Record<string | number | symbol, unknown>>proto)[key]);
    if (Array.isArray((<Record<string | number | symbol, unknown>>proto)[key])) {
        (<Record<string | number | symbol, unknown[]>>proto)[key].forEach(
            (k: string | number | symbol) => readonly((<Record<string | number | symbol, unknown>>proto)[key], k)
        );
    }
    else if (typeof (<Record<string | number | symbol, unknown>>proto)[key] === 'object') {
        Object.keys((<Record<string | number | symbol, unknown>>proto)[key]).forEach(
            (k) => readonly((<Record<string | number | symbol, unknown>>proto)[key], k)
        );
    }
}

type StaticGlobals = typeof Globals;
declare module './globals.service' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Globals extends StaticGlobals { }
}

// #endregion

/**
 * These tokens are not recognized by the language generator.
 * Add tokens manually to prevent them from being deleted on new generations.
 */
_([
	"CREDENTIAL.INFO.NO_CONTRACTS",
	"CREDENTIAL.INFO.NO_ACTIVE_CONTRACT",
	"SHIFT.CONTEXT_MENU.DUPLICATE",
	"SHIFT.CONTEXT_MENU.DELETE",
	"DATE.NAME.0",
	"DATE.NAME.1",
	"DATE.NAME.2",
	"DATE.NAME.3",
	"DATE.NAME.4",
	"DATE.NAME.5",
	"DATE.NAME.6",
	"DATE.NAME.7",
	"DATE.TOKENS.YEAR",
	"DATE.TOKENS.MONTH",
	"DATE.TOKENS.DAY",
	"DATE.TOKENS.HOUR",
	"DATE.TOKENS.MINUTE",
	"DATE.TOKENS.SECOND",
]);


@Injectable()
export class Globals {
    constructor() { throw new Error('Globals is not meant to be instantiated'); }

    @readonly static fUser = formatUser;
    @readonly static fTime = formatTime;
    @readonly static fDate = formatDateTime;

    /**
     * Lightness for profile color in light mode
     */
    @readonly static LIGHT_MODE_LIGHTNESS = 0.35;

    /**
     * Lightness for profile color in dark mode
     */
    @readonly static DARK_MODE_LIGHTNESS = 0.5;

    @readonly
    static timeUnit = {
        default: {
            [TimeUnit.None]:       "",
            [TimeUnit.Milisecond]: "Millisekunden",
            [TimeUnit.Second]:     "Sekunden",
            [TimeUnit.Minute]:     "Minuten",
            [TimeUnit.Hour]:       "Stunden",
            [TimeUnit.Day]:        "Tage",
            [TimeUnit.Week]:       "Wochen",
            [TimeUnit.Month]:      "Monate",
            [TimeUnit.Year]:       "Jahre",
        },
        short: {
            [TimeUnit.None]:       "",
            [TimeUnit.Milisecond]: "Ms",
            [TimeUnit.Second]:     "Sek",
            [TimeUnit.Minute]:     "Min",
            [TimeUnit.Hour]:       "h",
            [TimeUnit.Day]:        "T",
            [TimeUnit.Week]:       "W",
            [TimeUnit.Month]:      "M",
            [TimeUnit.Year]:       "J",
        },
        adjective: {
            [TimeUnit.None]:       "",
            [TimeUnit.Milisecond]: "ms",
            [TimeUnit.Second]:     "sek",
            [TimeUnit.Minute]:     "min",
            [TimeUnit.Hour]:       "h",
            [TimeUnit.Day]:        "t",
            [TimeUnit.Week]:       "w",
            [TimeUnit.Month]:      "mtl",
            [TimeUnit.Year]:       "j",
        }
    };

    @readonly
    static DateTime = {
        TIME_SIMPLE:           DateTime.TIME_SIMPLE,
        TIME_24_SIMPLE:        DateTime.TIME_24_SIMPLE,
        DATE_SHORT:            DateTime.DATE_SHORT,
        DATETIME_SHORT:        DateTime.DATETIME_SHORT,
        DATETIME_FULL:         DateTime.DATETIME_FULL,
        DATETIME_HUGE:         DateTime.DATETIME_HUGE,
        DATE_MED_WITH_WEEKDAY: DateTime.DATE_MED_WITH_WEEKDAY,
        /**
         * Show the current day, month, without the year.
         * Formats to `DD. M.` in `DE-DE`.
         */
        DATE_NO_YEAR:          {
            day:   "numeric",
            month: "numeric",
        },
        /**
         * Show the current day, month and weekday, without the year.
         * Formats to `DDDD, DD. M.` in `DE-DE`.
         */
        WEEKDAY_DATE_NO_YEAR: {
            day:     "numeric",
            month:   "numeric",
            weekday: "long",
        },
    };

    @readonly
    static ContactType = {
        Undefined: ContactType.Undefined,
        EMail:     ContactType.EMail,
        Phone:     ContactType.Phone,
        Mobile:    ContactType.Mobile,
        Fax:       ContactType.Fax	,
    };

    static toWeekDayFC(day: WeekdayFC) {
        const dayNames = AppService.instance.getTranslation("dayNames") as string[];
        return day ? (dayNames[day] ?? 'INVALID') : 'INVALID';
    }

    @readonly
    static DayOfWeek = DayOfWeek;

    @readonly
    static projectStatusLabels = {
        [ProjectStatus.none]:                   '',
        [ProjectStatus.planning]:               'In Planung',
        [ProjectStatus.active]:                 'Aktiv',
        [ProjectStatus.pause]:                  'Pausiert',
        [ProjectStatus.inactive]:               'Inaktiv',
        [ProjectStatus.completed]:              'Abgeschlossen',
        [ProjectStatus.completedAutomatically]: 'Automatisch beendet',
    };

    @readonly
    static checkinStatus = {
        none:      CheckinStatus.none,
        checkedin: CheckinStatus.checkedin,
        inPause:   CheckinStatus.inPause
    };

    @readonly
    static publishTypeLabels = {
        never:           'Entwurf',
        automaticallyAt: 'Anstehende Änderung',
        now:             ''
    };

    @readonly
    static terminalMediaType = {
        [TerminalMediaType.dynamic]: 'Tablet',
        [TerminalMediaType.static]:  'Druck',
        [TerminalMediaType.gt7]:     'Gantner GT7',
    };

    // @readonly
    // static paymentTimeUnits = {
    //     [TimeUnit.Week]: 'Wochen',
    //     [TimeUnit.Day]:  'Tage'
    // };

    @readonly
    static surchargeValueType = {
        percent:  SurchargeValueType.percent,
        absolute: SurchargeValueType.absolute
    };

    @readonly
    static absenceCreditType = {
        doesNotExpire:      AbsenceCreditAccountType.doesNotExpire,
        expiresAfter:       AbsenceCreditAccountType.expiresAfter,
        expiresAtEndOfYear: AbsenceCreditAccountType.expiresAtEndOfYear

    };

    @readonly
    static absencePaymentType = {
        paid:                     AbsencePaymentType.paid,
        unpaid:                   AbsencePaymentType.unpaid,
        partly:                   AbsencePaymentType.partly,
        substractFromTimeAccount: AbsencePaymentType.substractFromTimeAccount,
        paidMaxDuration:          AbsencePaymentType.paidMaxDuration

    };

    @readonly
    static absenceVerificationType = {
        none:           AbsenceVerificationType.none,
        alwaysRequired: AbsenceVerificationType.alwaysRequired,
        requiredAfter:  AbsenceVerificationType.requiredAfter
    };

    @readonly
    static checkTimeRounding = {
        NoRounding:           CheckTimeRounding.NoRounding,
        RoundDown:            CheckTimeRounding.RoundDown,
        RoundUp:              CheckTimeRounding.RoundUp,
        RoundOnNMinutesAfter: CheckTimeRounding.RoundOnNMinutesAfter
    };

    @readonly
    static checkInTerminalOptions = {
        QRTerminal_checkin:   TerminalCheckinMode.QRTerminal_checkin,
        QRTerminal_checkout:  TerminalCheckinMode.QRTerminal_checkout,
        QRTerminal_pause:     TerminalCheckinMode.QRTerminal_pause,
        QRTerminal_pauseEnde: TerminalCheckinMode.QRTerminal_pauseEnde,
        IP_checkin:           TerminalCheckinMode.IP_checkin,
        IP_checkout:          TerminalCheckinMode.IP_checkout,
        IP_pause:             TerminalCheckinMode.IP_pause,
        IP_pauseEnd:          TerminalCheckinMode.IP_pauseEnd,
        ALL_checkin:          TerminalCheckinMode.IP_checkin | TerminalCheckinMode.QRTerminal_checkin,
        ALL_pause:            TerminalCheckinMode.IP_pause | TerminalCheckinMode.QRTerminal_pause,
        ALL_pauseEnde:        TerminalCheckinMode.IP_pauseEnd | TerminalCheckinMode.QRTerminal_pauseEnde,
        ALL_checkout:         TerminalCheckinMode.IP_checkout | TerminalCheckinMode.QRTerminal_checkout
    };

    @readonly
    static icons = {
        //"island-tropical": 'Palmeninsel tropische Insel',
        "viruses":           'Virus krank Erkrankung',
        "syringe":           'Spritze krank Erkrankung',
        "sun":               'Sonne',
        "car-side":          'Auto Reise',
        "plane":             'Flugzeug Reise verreisen Urlaub',
        "stethoscope":       'Stethoskop Arzt krank',
        "baby":              'Elternzeit Schwangerschaft Kind',
        "baby-carriage":     'Kinderwagen Elternzeit Kind',
        "building-columns":  'Universität Schule Bildung',
        "user-graduate":     'Universität Abschluss Weiterbildung',
        "graduation-cap":    'Universität Abschluss Weiterbildung',
        "person-chalkboard": 'Fortbildung Training Schulung',
        "book-open-reader":  'Ausbildung Berufsschule',
        "mountain-sun":      'Reisen Berge Urlaub',
        "passport":          'Reisepass Reisen Sabbatical',
        "door-closed":       'blockiert nicht verfügbar geschlossen',
        "paint-roller":      'Umzug renovieren',
        "ring":              'Ring Hochzeit',
        "cross":             'Kreuz Trauerfall Beerdigung',
        "place-of-worship":  'Gebetshaus Religion religiös',
        "wheelchair":        'Behinderung Barrierefreiheit',
        "snowflake":         'Schnee Eis Kälte Wetter',
        "umbrella":          'Wetter Regen Schirm',
        "umbrella-beach":    'Urlaub'
    };

    @readonly
    static scheduledTaskStatusLabels = {
        [ScheduledTaskStatus.pending]:   'Ausstehend',
        [ScheduledTaskStatus.running]:   'Läuft',
        [ScheduledTaskStatus.completed]: 'Abgeschlossen',
        [ScheduledTaskStatus.failure]:   'Fehler',
    };

    @readonly
    static VisibilityType = {
        [VisibilityType.all]:                      'Alle',
        [VisibilityType.custom]:                   'Bestimmte Abteilungen (inkl. Leitung und Stabste...',
        [VisibilityType.none]:                     'Nur sich selbst',
        [VisibilityType.department]:               'Diese Abteilung (inkl. Leitung und Stabsstellen)',
        [VisibilityType.department_subdepartment]: 'Diese Abteilung und untergeordnete Abteilungen',
    };

    @readonly
    static VisibilityTargetLeader = {
        [VisibilityTargetLeader.leader]:            'Leitung',
        [VisibilityTargetLeader.leader_substitute]: 'Leitung und Vertretung',
    };

    @readonly
    static VisibilityTarget = {
        [VisibilityTarget.department]:               'Diese Abteilung (inkl. Leitung und Stabsstellen)',
        [VisibilityTarget.department_subdepartment]: 'Diese Abteilung und untergeordnete Abteilungen',
    };

    @readonly
    static VisibilityName = VisibilityName;

    /**
     * **IMPORTANT** 
     * Labels of new VisibilityName values must be added below to show within the orgchart.
     * Without adding a label users will not be able to configure the new visibility.
     */
    @readonly
    static VisibilityNameLabel: {[key in VisibilityName]?: string} = {
        absence_list:             "Abwesenheit (Übersicht und Auswertungen)",
        attendance_list:          "Check-in > Anwesenheit",
        orgchart:                 "Organisation > Organigramm",
        shift_planning:           "Schichtplanung",
        calendar:                 "Kalender",
        person_search:            "Personensuche",
        working_time_analyses:    "Auswertungen > Arbeitszeit",
        time_corrections_confirm: "Arbeitszeit Korrekturanträge",
        salary_stats:             "Auswertungen > Stundensatz",
    };

    @readonly
    static TerminalTrackingEvents = {
        none:                   TerminalTrackingEvents.none,
        checkin_checkout:       TerminalTrackingEvents.checkin_checkout,
        checkin_checkout_pause: TerminalTrackingEvents.checkin_checkout_pause
    };

    @readonly
    static WorkHoursType = {
        NumberOfHoursPerWeek: WorkHoursType.NumberOfHoursPerWeek,
        FixedSchedule:        WorkHoursType.FixedSchedule,
        ShiftPlanning:        WorkHoursType.ShiftPlanning
    };

    @readonly
    static OpeningTimeType = {
        Regular:    OpeningTimeType.Regular,
        Closed:     OpeningTimeType.Closed,
        Changed:    OpeningTimeType.Changed,
        LikeSunday: OpeningTimeType.LikeSunday,
    };

    @readonly
    static ShiftFailureReason: Record<ShiftFailureReason, string> = {
        "CONFLICT":            "Konflikt",
        "INVALID_DEPARTMENT":  "Ungültige Abteilung",
        "INVALID_USER":        "Ungültige Person",
        "MISSING_DEPARTMENT":  "Abteilung fehlt",
        "MISSING_START":       "Start fehlt",
        "MISSING_END":         "Ende fehlt",
        "MISSING_USER":        "Person fehlt",
        "IMPORT_OUT_OF_RANGE": "Übertrifft die maximale Importgröße",
        "UNSPECIFIED":         "Unspezifizierter Fehler",
    };
}
