import { Inject, Injectable, Optional } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRouteSnapshot, NavigationBehaviorOptions, NavigationExtras, Router, UrlTree } from "@angular/router";
import { v4 as uuid } from 'uuid';
import { MenuItem } from "primeng/api";
import { IDraft } from "@vierkant-software/types__api";
import { FileUploadInfo } from "@vierkant-software/remoterpc";
import { UrlCreationOptions } from '@angular/router';
import { BehaviorSubject, Observable } from "rxjs";
import { IAppService } from "../types/IAppService";
import { DraftDefinition } from "../types/draft-definition";
import { ExitState } from "../types/exit-state";
import { PageButton, PageButtons, PageButtonTypes } from "../types/pagebuttons";
import { Step } from "../types/step";
import { StepDefinition } from "../types/step-definition";
import { APP_SERVICE, DebugFlags, DRAFT_DEBUG } from "../types/token";
import { DraftHostComponent } from "./components/draft-host-component";
import { DateTime } from "luxon";
import { InvalidError } from "src/util/errors";

//const oldNavigate = Router.prototype.navigate;
const oldNagigateByUrl = Router.prototype.navigateByUrl;
const oldCreateUrlTree = Router.prototype.createUrlTree;

//createUrlTree(commands: any[], navigationExtras: UrlCreationOptions = {}): UrlTree {
// eslint-disable-next-line @typescript-eslint/space-before-function-paren
Router.prototype.createUrlTree = function(commands: string[], navigationExtras: UrlCreationOptions = {}): UrlTree {
    if (['$', '%', '&', '§'].includes(commands?.[0])) return <UrlTree>commands;
    return oldCreateUrlTree.apply(this, [commands, navigationExtras]);
};


const MODAL_CONFIG = { };

// navigateByUrl(url: string|UrlTree, extras: NavigationBehaviorOptions = { skipLocationChange: false }): Promise<boolean>
// eslint-disable-next-line @typescript-eslint/space-before-function-paren
Router.prototype.navigateByUrl = async function(
        url: string | UrlTree | string[], extras: NavigationBehaviorOptions = { skipLocationChange: false }): Promise<boolean> {
    const originalUrl = url;
    if (typeof url === 'object' && url instanceof UrlTree && ['$', '%', '&', '§'].includes(url?.root?.children?.primary?.segments?.[0].path))
        url = url?.root?.children?.primary?.segments?.map(x => x.path);
    if (Array.isArray(url) && ['$', '%', '&', '§'].includes(url[0])) {
        if (['$', '&'].includes(url?.[0]) && [4,5].includes(url.length)) {
            const baseURI = url[1];
            const loadDraftID = url[2];
            const anchor = url[3];
            const options = url[4] ?? {};
            return DraftService.instance.createDraft(baseURI, anchor, { ...options, openAsModal: url[0] === '&', loadDraftID, extras });
        } else if (['%', '§'].includes(url?.[0]) && [3, 4].includes(url.length)) {
            const baseURI = url[1];
            const anchor = url[2];
            const RecordID = url[3];
            return DraftService.instance.createDraft(baseURI, anchor, { openAsModal: url[0] === '§', RecordID, extras });
        } else {
            throw new Error('Invalid number of draft params in nagivateByUrl');
        }
    }
    return await oldNagigateByUrl.apply(this, [originalUrl, extras]);
};

// Router.createUrlTree

export interface IFrontendDraft extends Omit<IDraft, 'subType'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subType?: any;  //TODO: remove after backend modification
}

declare global {
    export interface Window {
        draftDebug(): void;
    }
}

@Injectable({
    providedIn: 'root',
})
export class DraftService {
    _hostComponent: DraftHostComponent;

    static instance: DraftService;
    constructor(
        private formBuilder: FormBuilder,
        @Inject(APP_SERVICE) @Optional() private appService: IAppService, //TODO: AppService???
        @Inject(DRAFT_DEBUG) @Optional() public debug: DebugFlags,
        private router: Router
    ) {
        window.draftDebug = () => {
            console.log('top level valid:', Object.entries(this.#currentForm.controls).map(([key, c]) => [key, c.valid]));
            console.log('steps valid:', this.isStepValid);
            console.log('form valid:', this.form.valid);
            console.log('form data:', this.form.value);
            console.log('form dirty:', this.form.dirty);
            console.log('form:', this.form);
        };
        DraftService.instance = this;
    }

    setLoginState(login: boolean) {
        if (login)
            { this.#updateDraftArchive().catch((e) => console.error(e)); }
        else {
            this.#draftArchive = {};
            this.clearDraft().catch((e) => console.error(e));
            this.#draftArchiveUpdate.next([]);
        }
    }


    /** @internal */
    isStepValid: { [step: string]: boolean } = {};

    #daLoad: Promise<Record<string, unknown>>;

    #currentDraft: IFrontendDraft;
    #currentBreadCrumb: MenuItem[];
    #currentDefinition: DraftDefinition<unknown>;
    #currentForm: FormGroup;
    #draftArchive: { [id: string]: IFrontendDraft } = {};
    #resolveData: { [param: string]: unknown };

    #createInfo: DraftCreateInfo & IFrontendDraft & { mode: 'create' | 'load' | 'return' | 'dispose' };

    #draftArchiveUpdate: BehaviorSubject<IDraftInfo[]> = new BehaviorSubject([]);

    get $draftArchiveUpdate(): Observable<IDraftInfo[]> {
        return this.#draftArchiveUpdate.asObservable();
    }

    get anchor() {
        return this.#currentDraft?.anchor;
    }

    get breadcrumb(): MenuItem[] {
        return this.#currentBreadCrumb;
    }

    get isInModal(): boolean {
        return this.#currentDraft?.subType?.openAsModal ?? false;
    }

    get isDraft(): boolean {
        return !!this.#currentDraft;
    }

    get showAllSteps(): boolean {
        return this.#currentDraft?.subType?.showAllSteps ?? (this.isInModal ? false : true);
    }

    getButtonLabel(type: PageButtonTypes) {
        const steps = this.steps;
        const step = steps.findIndex(x => x.anchor === this.#currentDraft?.anchor);
        const stepDef: StepDefinition<unknown> = steps[step].step;
        let overrides: { [button in PageButtonTypes]?: string };
        if (typeof stepDef.config?.buttonLabels === 'function')
            { overrides = stepDef.config.buttonLabels({
                router:       this.router,
                form:         this.#currentForm,
                draft:        this.#currentDraft,
                appService:   this.appService,
                self:         stepDef,
                stepper:      this.#currentDefinition,
                draftService: this
            }); }
        else
            { overrides = stepDef.config?.buttonLabels ?? {}; }
        if (overrides[type])
            return overrides[type];

        if (type === PageButtonTypes.submit) {
            //console.log('BL', step, steps, steps.length - 1, this.#currentDraft.parentDraftID, type);
            if (step !== steps.length - 1)
                return 'Eingabe abschließen';
            if (this.#currentDraft.parentDraftID)
                return 'Speichern & Weiter';
            return 'Speichern';
        }
        return undefined;   //FIXME: implemetation
    }

    get buttons(): PageButtons {
        const steps = this.steps;
        const stepId = this.stepId;
        const stepDef: StepDefinition<unknown> = this.#currentDefinition?.steps.filter(x => x.anchor === this.#currentDraft?.anchor)?.[0];
        const buttons: PageButtons = [];
        if (!stepDef)
            return buttons;
        const validOverride = stepDef.config?.valid?.({ form: this.#currentForm, draft: this.#currentDraft }, stepDef, this.#currentDefinition);
        if (typeof stepDef.config?.delete === 'function') {
            buttons.push({
                type:  'delete',
                label: "",
                icon:  'pi pi-trash',
                click: async (event: Event) => {
                    const result = await stepDef.config.delete({
                        router:       this.router,
                        form:         this.#currentForm,
                        draft:        this.#currentDraft,
                        appService:   this.appService,
                        self:         stepDef,
                        stepper:      this.#currentDefinition,
                        draftService: this,
                        event,
                    });
                    if (result) {
                        const isModal = this.isInModal;
                        await this.destroyDraft();
                        if (typeof result === 'boolean') {
                            if (isModal)
                                await this.appService.navigateModal(null);
                            else
                                await this.router.navigate(this.#currentDraft.subType.cancelURL ?? ['/']);
                        } else {
                            if (isModal)
                                await this.appService.navigateModal(null);
                            await this.router.navigateByUrl(result);
                        }
                    }
                },
                color: 'p-button-danger'
            });
        }
        if (stepId > 0)
            { buttons.push({
                type:       'prevStep',
                label:      this.getButtonLabel(PageButtonTypes.prevStep) ?? "Zurück",
                routerLink: this.getStepLink(stepId - 1),
                color:      'p-button-primary',
                icon:       'pi pi-angle-left'
            }); }

        // TODO: discuss if we should always show the cancel button (also in child drafts)
        // if we allow this on child drafts, we have to traverse to the (top most) parent and call `destroyDraft` on it
        if (!this.#currentDraft.parentDraftID) {
            buttons.push({
                type:  'cancel',
                label: this.getButtonLabel(PageButtonTypes.cancel) ?? 'Abbrechen',
                click: async (_event) => {
                    console.log("destroy self");
                    if (this.#currentDraft.subType.openAsModal) {
                        await this.destroyDraft();
                        await this.appService.navigateModal(null);
                    } else {
                        await this.destroyDraft();
                        await this.router.navigate(this.#currentDraft.subType.cancelURL ?? ['/']);
                    }
                },
                color: 'p-button-danger',
            });
        }

        if (((stepId >= steps.length - 1) ||
            (
                (typeof stepDef.config?.showSubmitButton === 'boolean' && stepDef.config?.showSubmitButton) ||
                (typeof stepDef.config?.showSubmitButton === 'function' &&
                    stepDef.config?.showSubmitButton({ form: this.#currentForm, self: stepDef, stepper: this.#currentDefinition })) ||
                (typeof stepDef.config?.showSubmitButton === 'undefined' && !!this.#currentDraft.RecordID)
            )) && !this.isReadonly
        )
            { buttons.push({
                type:     'submit',
                label:    this.getButtonLabel(PageButtonTypes.submit) ?? 'Eingabe Abschließen',
                click:    (_event) => this.#finalClicked(),
                disabled: Object.values(this.steps)
                    .some(x => !(x.step.config?.valid?.({ form: this.#currentForm, draft: this.#currentDraft }, x.step, this.#currentDefinition) ?? x.valid)),
                color: 'p-button-success',
            }); }
        if (stepId < steps.length - 1 && steps.length > 1)
            { buttons.push({
                type:       'nextStep',
                label:      this.getButtonLabel(PageButtonTypes.prevStep) ?? "Weiter",
                routerLink: this.getStepLink(stepId + 1),
                disabled:   !(validOverride ?? this.isFormValid),
                color:      'p-button-primary',
                icon:       'pi pi-angle-right',
                iconPos:    'right'
            }); }
        return buttons;
    }

    get backToParentButton(): PageButton {
        if (!this.#currentDraft.parentDraftID) return undefined;
        const parent = this.#draftArchive[this.#currentDraft.parentDraftID];
        if (!parent) return undefined;
        return {
            type:       'prevDraft',
            label:      this.getButtonLabel(PageButtonTypes.prevDraft) ?? 'Zurück' /*zum Parent*/,
            routerLink: [this.#currentDraft?.subType?.openAsModal ? '&' : '$', parent.baseURI, parent.draftID, parent.anchor, { omitChilds: true }],
            color:      'p-button-primary p-button-outlined',
            icon:       'pi pi-angle-double-left'
        };
    }

    /**
     * @internal
     */
    get _resolveData() {
        return this.#resolveData;
    }

    /**
     * resolve data of the last resolved step
     */
    get resolveData() {
        return this.#resolveData;
    }

    /**
     * @internal
     */
    get _current_definition() {
        return this.#currentDefinition;
    }

    get draftId() {
        return this.#currentDraft?.draftID;
    }

    get form() {
        return this.#currentForm;
    }

    get isFormValid() {
        return this.isStepValid[this.#currentDraft?.anchor];
    }

    get recordId() {
      return this.#currentDraft?.RecordID;
    }

    set recordId(id) {
        this.#currentDraft.RecordID = id;
    }

    get steps(): Step[] {
        if (!this.#currentDraft?.draftID || !this.#currentForm) return [];

        const steps = this.#currentDefinition?.steps
            ?.filter((step) =>
                (this.showAllSteps || this.#currentDraft?.anchor === step.anchor) &&
                (!step.config?.hidden?.({ form: this.#currentForm, draft: this.#currentDraft }, step, this.#currentDefinition) ?? true)
            )
            .map(step => <Step>{
                //link: this.getLink(step.anchor),
                component: step.component,
                anchor:    step.anchor,
                label:     step.label,
                valid:     this.isStepValid[step.anchor],
                disabled:  step.config?.disabled?.({ form: this.#currentForm, draft: this.#currentDraft }, step, this.#currentDefinition) ?? false,
                step,
            });
        return steps;
    }

    get stepId(): number {
        return this.steps?.findIndex(x => x.anchor === this.#currentDraft?.anchor) || 0;
    }

    get isReadonly(): boolean {
        return this.#currentDraft?.subType?.readonly ?? false;
    }

    generateBreadCrumb() {
        const bc: MenuItem[] = [];
        let current = this.#currentDraft;
        while (current) {
            bc.unshift({
                label:      current.title ?? "XXX",
                routerLink: ['$', current.baseURI, current.draftID, current.anchor, { omitChilds: true }]
            }); // TODO: BreadCrumb name
            current = this.#draftArchive[current.parentDraftID];
        }
        this.#currentBreadCrumb = bc;
    }

    async destroyDraft(id: string = this.#currentDraft?.draftID, updateArchive = true) {
        await Promise.all(
            Object.values(this.#draftArchive)
                  .filter((d) => d.parentDraftID === id)
                  .map((d) => this.destroyDraft(d.draftID, false))
        );
        if (id === this.#currentDraft?.draftID)
            this.#createInfo = { mode: 'dispose' };
        if (!this.#draftArchive[id])
            return;
        await this.appService.api.DraftWorker.DeleteDraft(id).catch(console.error); //FIXME: Propper error handling
        if (updateArchive)
            await this.#updateDraftArchive();
    }

    async #finalClicked() {
        if (this.isReadonly) {
            console.error("Final clicked in readonly mode");
            return;
        }

        if (this.#currentDraft.lockIdentifier?.length > 0) {
            await this.#updateDraftArchive();
            if (this.#draftArchive[this.#currentDraft.draftID].lockIsCancelled) {
                this.#currentDraft.subType.readonly = true;
                return;
            }
        }
        const result = await this.#currentDefinition?.final?.(this.#currentForm, this.#currentDraft, this.appService)
            .catch(x => {
                console.error('Exception in finalize():', x);
                return false;
            });
        if (result) {
            if (this.#currentDraft.parentDraftID) {
                await this.destroyDraft();
                await this.createDraft(undefined, this.#currentDraft?.returnToAnchor,
                    { loadDraftID: this.#currentDraft?.parentDraftID, returnData: result });
            } else {
                console.log(result);
                const exitPage = (typeof result === 'object' && result.returnTo) || ['/', 'exit'];
                const state = result !== true ? result : {};
                await this.destroyDraft();
                if (this.#currentDraft?.subType.openAsModal)
                    { this.appService.navigateModal(['exit'], { ...MODAL_CONFIG, exitState: state }, { state })
                        .catch(() => console.error('Error while navigating to exit page')); }
                else
                    { this.router.navigate(exitPage, { state })
                        .catch(() => console.error('Error while navigating to exit page')); }
            }
        }
    }


    getStepLink(step: number): string[] {
        return [this.#currentDraft?.subType?.openAsModal ? '&' : '$', this.#currentDefinition.baseurl, this.#currentDraft?.draftID, this.steps[step]?.anchor];
    }

    async navigateNextStep() {
        await this.router.navigate(this.getStepLink(this.stepId + 1));
    }

    async navigatePrevStep() {
        await this.router.navigate(this.getStepLink(this.stepId - 1));
    }

    #getDraftList() {
        return Object.values(this.#draftArchive)
                .filter((draft) => !draft.parentDraftID)
                .map(draft => <IDraftInfo>{
                    id:       draft.draftID,
                    title:    this.#getDraftTitle(draft.draftID),
                    link:     [draft.subType.openAsModal ? '&' : "$", draft.baseURI, draft.draftID, draft.anchor],
                    active:   this.#isActiveDraft(draft.draftID),
                    modified: draft.modified_at,
                    created:  draft.created_at,
                    icon:     draft.icon,
                    category: draft.category,
                });
    }

    #isActiveDraft(draftID: string): boolean {
        if (this.#currentDraft?.draftID === draftID)
            return true;
        if (this.#draftArchive[draftID]?.activeChildDraftID)
            return this.#isActiveDraft(this.#draftArchive[draftID].activeChildDraftID);
        return false;
    }

    #getDraftTitle(draftID: string): string {
        if (this.#draftArchive[draftID]?.activeChildDraftID){
            const childTitle = this.#getDraftTitle(this.#draftArchive[draftID].activeChildDraftID);
            if (childTitle.startsWith("+"))
                return this.#draftArchive[draftID].title + childTitle.substring(1);
            if (childTitle.length === 0)
                return this.#draftArchive[draftID].title;
            return childTitle;
        }
        return this.#draftArchive[draftID]?.title ?? "";
    }

    /**
     * Creates and loads a new Draft
     *
     * @param draft
     * @param type
     */
    async createDraft(baseURI: string, anchor: string, options: DraftCreateInfo = {}) {
        console.log('CreateDraft: ', arguments);
        await this.#updateDraftArchive();
        console.log(this.#draftArchive);
        if (options.chain && !options.loadDraftID) {
            const found = Object.values(this.#draftArchive).find((draft) =>
                draft.baseURI === baseURI &&
                draft.parentDraftID === this.#currentDraft?.draftID &&
                //draft.subType === options.subType && //TODO: implement subType
                draft.RecordID === options.RecordID
            );
            if (found)
                options.loadDraftID = found.draftID;
        };
        if (options.loadDraftID !== undefined) {
            if (options.loadDraftID === this.#currentDraft?.draftID) {
                baseURI = this.#currentDraft?.baseURI;
                this.#createInfo = { ...options, mode: options.returnData ? 'return' : 'load', draftID: options.loadDraftID};
                if (this.#currentDraft.subType.readonly)
                    this.#createInfo.readonly = true;
            } else {
                if (options.returnData)
                    options.omitChilds = true;
                if (anchor === undefined)
                    anchor = this.#draftArchive[options.loadDraftID].anchor;
                while (!options.omitChilds && this.#draftArchive[options.loadDraftID]?.activeChildDraftID) {
                    console.log("Child: ", this.#draftArchive[options.loadDraftID]?.activeChildDraftID);
                    const child = this.#draftArchive[options.loadDraftID]?.activeChildDraftID;
                    if (!this.#draftArchive[child]) {
                        console.warn('Inconsistent draft - active child does not exist');
                        options.omitChilds = true;
                        break;
                    }
                    options.loadDraftID = child;
                    anchor = this.#draftArchive[options.loadDraftID]?.anchor;
                }
                baseURI = this.#draftArchive[options.loadDraftID]?.baseURI;
                if (!baseURI) {
                    console.error('Navigating to draft not in draftArchive');
                    if (options?.openAsModal)
                        return;
                    return await this.router.navigate(['/']);
                }
                this.#createInfo = { ...options, mode: options.returnData ? 'return' : 'load', draftID: options.loadDraftID};
                if (options.returnData)
                    this.#createInfo.openAsModal = this.#draftArchive[options.loadDraftID]?.subType.openAsModal;
            }
            if (this.#draftArchive[options.loadDraftID]?.lockIdentifier?.length > 0) {
                if (this.#draftArchive[options.loadDraftID].lockIsCancelled) {
                    this.#createInfo.readonly = true;
                    this.#createInfo.resourceIDs = undefined;
                }
            }
        } else { /* create */
            this.#createInfo = { ...options, mode: 'create' };
            this.#createInfo.draftID = uuid();
            this.#createInfo.openAsModal = options.openAsModal;
            this.#createInfo.modalParentURL = options.modalParentURL ?? ['/'];
            if (options.chain) {
                this.#createInfo.parentDraftID = this.#currentDraft?.draftID;
                this.#createInfo.returnToAnchor = this.#currentDraft?.anchor;
                this.#createInfo.openAsModal = this.#currentDraft?.subType.openAsModal;
            }
            if (options.resourceIDs) {
                const activeLocks = await this.appService.api.DraftWorker.getResourceLockInfo(options.resourceIDs);
                if (activeLocks?.length > 0) {
                    const action = await this.appService.resolveDuplicatedLockIdentifier(activeLocks, undefined);
                    if (action === 'readonly') {
                        this.#createInfo.readonly = true;
                        this.#createInfo.resourceIDs = undefined;
                    } else if (action === 'overwrite')
                        { await this.appService.api.DraftWorker.overrideLocks(activeLocks.map(x => x.ID)); }
                    else
                        { throw new Error('Draft creation cancelled by user'); }
                }
            }
            //TODO: check if resources need to be and can be locked
        }
        if (typeof options.showAllSteps === 'boolean')
            this.#createInfo.showAllSteps = options.showAllSteps;
        if (this.#createInfo.openAsModal) {
            //console.log("CD", this.#draftArchive[options.loadDraftID], options);
            if (!this.#currentDraft?.subType.openAsModal) {
                if (this.#draftArchive[options.loadDraftID]?.subType.modalParentURL)
                    await this.router.navigate(this.#draftArchive[options.loadDraftID]?.subType.modalParentURL);
                else if (this.#currentDraft && this.#currentDraft?.draftID !== this.#createInfo.draftID) //TODO: currentDraft can be a modal
                    await this.router.navigate(['/']);
            }
            await this.appService.navigateModal([...baseURI.split('/').filter(x => x), this.#createInfo.draftID, anchor],
                MODAL_CONFIG ,options.extras ?? { skipLocationChange: false });
            return true;
        }
        return await this.router.navigate(['/', ...baseURI.split('/'), this.#createInfo.draftID, anchor], options.extras ?? { skipLocationChange: false });

    }

    async #updateDraftArchive() {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        if (this.#daLoad)
            return this.#daLoad;
        console.log("archive fetch");
        const fetching = this.appService.api.DraftWorker.ListDrafts()
            .then(drafts => Object.fromEntries(drafts.map(draft => [draft.draftID, draft])))
            .then(drafts => this.#draftArchive = drafts);
        fetching.then(() => this.#draftArchiveUpdate.next(this.#getDraftList())).catch(x => console.error(x));
        this.#daLoad = fetching.finally(() => this.#daLoad = undefined);
        return fetching;
    }

    /**
     * @internal
     */
    async initDraft(route: ActivatedRouteSnapshot): Promise<boolean> {
        const draftID = route.params.draft;
        if (!draftID)
            throw new Error('No draftID set'); //should never happen
        if (route.data.Stepper !== this.#currentDefinition)
            this._hostComponent?.cleanup();
        switch(this.#createInfo?.mode) {
            case 'create':
                if (this.#createInfo.RecordID !== undefined)
                    return await this.#createDraftFromRecord(route);
                return await this.#createDraft(route);
            case 'return':
                return await this.#returnFromChain(route);
            case 'load':
            default:
                if (route.params?.draft === this.#currentDraft?.draftID)
                    return await this.#navigateInsideDraft(route);
                return await this.#tryLoadDraft(route);
        }
    }

    async #runResolveData(step: StepDefinition<unknown>) {
        console.log('ResolveData triggered');
        this.#resolveData = Object.fromEntries(
            await Promise.all(
                Object.entries(step.config?.resolveData ?? {})
                    .map(async ([param, fetcher]) =>
                            [ param,
                                await fetcher.apply(this.appService.api, [
                                    {
                                        form:         this.#currentForm,
                                        draft:        this.#currentDraft,
                                        appService:   this.appService,
                                        self:         step,
                                        stepper:      this.#currentDefinition,
                                        draftService: this
                                    }
                                ])
                            ]
                    )
            ).then(x => {
                this.#draftArchiveUpdate.next(this.#getDraftList());
                return x;
            })
        );
    }


    #draftInfoFromRoute(route: ActivatedRouteSnapshot) {
        if (this.#createInfo?.draftID && this.#createInfo.draftID !== route.params.draft)
            throw new Error('Invalid draft ID During navigate'); // Cannot happen (hopefully?)
        const definition = <DraftDefinition<unknown>>route.data.Stepper;
        if (!definition)
            throw new Error('No draft definition'); //should never happen

        //!SECTION Intentionally modifying the draft definition on first load
        if (!definition.version)
            definition.version = 1;
        if (!definition.minVersion)
            definition.minVersion = definition.version;
        //!ENDSECTION

        const step = definition.steps.find((s) => s.anchor === route.data.anchor);
        if (!step)
            throw new Error('Requested Anchor doesn\'t exist'); //should never happen
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const subType: any = {};
        if (this.#createInfo?.cancelURL)
            subType.cancelURL = this.#createInfo?.cancelURL;
        if (this.#createInfo?.openAsModal)
            subType.openAsModal = true;
        if (this.#createInfo?.modalParentURL)
            subType.modalParentURL = this.#createInfo?.modalParentURL;
        if (typeof this.#createInfo?.showAllSteps === 'boolean')
            subType.showAllSteps = this.#createInfo.showAllSteps;
        if (this.#createInfo?.readonly)
            subType.readonly = this.#createInfo.readonly;
        const draft: IFrontendDraft = {
            anchor:         route.data.anchor,
            baseURI:        definition.baseurl.startsWith('/') ? definition.baseurl.substring(1) : definition.baseurl,
            draftID:        route.params.draft,
            icon:           definition.icon,
            subType,
            lockIdentifier: this.#createInfo?.resourceIDs,
        };
        return { definition, step, draft };
    }

    async #createDraft(route: ActivatedRouteSnapshot): Promise<boolean> {
        const routeInfo = this.#draftInfoFromRoute(route);
        if (!routeInfo.step.config?.create)
            throw new Error('Create not allowed');
        let data = {};
        if (typeof routeInfo.step.config?.create === 'function')
            data = await routeInfo.step.config.create(this.appService, this.#createInfo.data ?? {});
        data = { ...data, ...(this.#createInfo.data ?? {}) }; //TODO: Maybe deepMerge
        routeInfo.draft.parentDraftID = this.#createInfo.parentDraftID;
        routeInfo.draft.returnToAnchor = this.#createInfo.returnToAnchor;
        routeInfo.draft.version = routeInfo.definition.version;
        if (this.#currentDraft && this.#createInfo.chain) {
            this.#setActiveChild(route.params.draft);
            await this.save(true);
        }
        this.#currentDefinition = routeInfo.definition;
        this.#currentForm = routeInfo.definition.controls.getFormGroup(this.formBuilder, data);
        this.#currentDraft = routeInfo.draft;
        this.#createInfo = undefined;
        if (routeInfo.draft.lockIdentifier?.length > 0)
            await this.save(true);
        await this.#runResolveData(routeInfo.step);
        this.generateBreadCrumb();
        return true;
    }

    async #createDraftFromRecord(route: ActivatedRouteSnapshot): Promise<boolean> {
        let save = false;
        const routeInfo = this.#draftInfoFromRoute(route);
        if (routeInfo.step.config?.canLoad === false)
            throw new Error('Load not allowed from this step');
        routeInfo.draft.parentDraftID = this.#createInfo.parentDraftID;
        routeInfo.draft.returnToAnchor = this.#createInfo.returnToAnchor;
        routeInfo.draft.RecordID = this.#createInfo.RecordID;
        routeInfo.draft.version = routeInfo.definition.version;
        if (this.#currentDraft && this.#createInfo.chain) {
            this.#setActiveChild(route.params.draft);
            save = true;
        }
        if (save || routeInfo.draft.lockIdentifier?.length > 0)
            await this.save(true);
        let data = await routeInfo.definition.fetch(this.#createInfo.RecordID, this.appService);
        data = { ...data, ...(this.#createInfo.data ?? {}) }; //TODO: Maybe deepMerge
        if (this.#createInfo.copy) {
            routeInfo.draft.RecordID = undefined;
            data = await routeInfo.definition.copy?.(data, this.appService, this.#createInfo.RecordID,) as Record<string, unknown>;
        }
        this.#currentForm = routeInfo.definition.controls.getFormGroup(this.formBuilder, data);
        this.#currentDraft = routeInfo.draft;
        this.#createInfo = undefined;
        this.#currentDefinition = routeInfo.definition;
        await this.#runResolveData(routeInfo.step);
        this.generateBreadCrumb();
        return true;
    }

    async #tryLoadDraft(route: ActivatedRouteSnapshot): Promise<boolean> {
        const routeInfo = this.#draftInfoFromRoute(route);
        const oldDraft = this.#currentDraft;
        const omitChilds = this.#createInfo?.omitChilds;
        console.log(this.#createInfo);
        try {
            const draft = await this.appService.api.DraftWorker.LoadDraft(route.params.draft);
            if (this.#currentDraft?.activeChildDraftID && this.#createInfo.omitChilds && !this.#createInfo?.chain) {
                this.#currentDraft.activeChildDraftID = undefined;
                await this.save();
            }
            if (this.#currentDraft && this.#createInfo?.chain) {
                this.#setActiveChild(route.params.draft);
                await this.save();
            }
            this.#currentDraft = draft.data; //TODO: Maybe rethrow propper exception
            if (this.#currentDraft.version < routeInfo.definition.minVersion)
                throw InvalidError.MinDraftVersion();
            if (this.#currentDraft.version > routeInfo.definition.version)
                throw InvalidError.ClientVersion();
            if (this.#currentDraft.version < routeInfo.definition.version) {
                // intentional without ?. because we want to throw an error if the function is not defined
                this.#currentDraft.data = routeInfo.definition.upgradeVersion(this.#currentDraft.data, this.#currentDraft.version);
                this.#currentDraft.version = routeInfo.definition.version;
            }
            this.#currentDraft.anchor = route.data.anchor;
            const form = routeInfo.definition.controls.getFormGroup(this.formBuilder, undefined);
            const fileInfo = Object.fromEntries(Object.entries(this.#currentDraft.filePath2ID).map(([path, idx]) => [path, draft.__files[+idx]]));
            form.setDraftValues(this.#currentDraft.data);
            form.__GCFiles = fileInfo;
            form.setFiles(fileInfo);
            form.updateValidators();
            if (this.#currentDraft.RecordID &&
                await routeInfo.definition.isRecordModified?.(form, this.#currentDraft, this.appService) &&
                await this.#recordModifiedQuery()
            )
                return true;

            this.#currentForm = form;
            this.#currentDefinition = routeInfo.definition;
            if (omitChilds) {
                this.#currentDraft.activeChildDraftID = null;
                await this.save();
            }
            this.#createInfo = undefined;
            await this.#runResolveData(routeInfo.step);
            this.generateBreadCrumb();
        } catch(e) {
            this.#currentDraft = oldDraft;
            e.displayErrorToast();
            throw e;
        }
        return true;
    }

    #setActiveChild(id: string) {
        if (this.#currentDraft?.draftID === id)
            return console.error('Cannot set active child to itself');
        let draft = this.#currentDraft?.draftID;
        while (draft) {
            if (this.#draftArchive[draft]?.parentDraftID === id)
                return console.error('Cannot set activeDraft to any parent draft');
            draft = this.#draftArchive[draft]?.parentDraftID;
        }
        this.#currentDraft.activeChildDraftID = id;
    }

    async #returnFromChain(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (this.#createInfo.readonly)
            this.#currentDraft.subType.readonly = true;
        const returnData = this.#createInfo.returnData;
        await this.#tryLoadDraft(route);
        const definition = <DraftDefinition<unknown>>route.data.Stepper;
        const step = definition.steps.find((s) => s.anchor === route.data.anchor);
        await step.config.returnFromChild?.(returnData, this.#currentForm, step, definition, this.appService, this);
        return true;
    }

    async #navigateInsideDraft(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (this.#createInfo?.readonly)
            this.#currentDraft.subType.readonly = true;
        this.#currentDraft.anchor = route.data.anchor;
        this.#createInfo = undefined;
        const step = this.#currentDefinition.steps.find((s) => s.anchor === route.data.anchor);
        await this.#runResolveData(step);
        if (await step?.config?.onEnter?.(this.#currentForm, this.#currentDraft, this.appService, step, this.#currentDefinition))
            await this.save();
        this.generateBreadCrumb();
        return true;
    }

    async clearDraft(id?: string) {
        if (!id || this.#currentDraft.draftID === id) {
            this.#currentDraft = undefined;
            this.#currentDefinition = undefined;
            this.#currentForm = undefined;
            this.#currentBreadCrumb = undefined;
        }
    }

    async save(force = false) {
        if (!force && this.#currentForm.pristine && !this.#currentDraft?.activeChildDraftID)
            return false;
        if (this.#createInfo?.mode === 'dispose') {
            this.#createInfo = undefined;
            return false;
        }
        if (this.#createInfo?.mode === 'return')
            return false;
        this.#currentDraft.title = this.#currentDefinition.title(this.#currentForm).trim();
        /*if (!this.#currentDraft.title && !this.#currentDraft.parentDraftID)
            return; //don't save parent drafts without title*/
        this.#currentDraft.icon = this.#currentDefinition.icon;
        this.#currentDraft.category = this.#currentDefinition.category;
        this.#currentDraft.data = <Record<string, unknown>>this.#currentForm.getDraftValues();   // sideffect: populates draft.form.__GCFileUpload
        console.log('Save: ', this.#currentDraft);
        const files: FileUploadInfo = {};
        const filePath2ID: Record<string, string> = {};
        await Promise.all(Object.entries(this.#currentForm.__GCFileUpload).map(async ([path, fileURL], i) => {
            files[i] = await fetch(fileURL).then(res => res.blob()).then(async res => ({ data: await res.arrayBuffer(), ContentType: res.type }));
            filePath2ID[path] = <string><unknown>i;
        }));
        this.#currentDraft.filePath2ID = filePath2ID;
        if (this.#currentForm.__GCFiles && this.#currentForm.__GCFileUpload)
            Object.assign(this.#currentForm.__GCFiles, this.#currentForm.__GCFileUpload);
        return this.appService.api.DraftWorker.SaveDraft(files, this.#currentDraft).then(x => {
            this.#updateDraftArchive().catch(y => console.error('Update draftArchive failed: ', y)); //TODO: propper toast
            return x;
        });
    }

    /**
     * @return
     *  false to continue loading the draft,
     *  true to cancel loading and start own navigation to new draft
     * @throws Error to cancel navigation
     */
    async #recordModifiedQuery(): Promise<boolean> {
        //TODO: Implementation
        throw new Error('not implemented');
    }



}

export interface DraftCreateInfo {
    /** @internal */
    subType?: string;
    /** Data to be patched into form. NOTE: only top level objects are merged, no deep merge! */
    data?: Record<string, unknown>;
    /** Record ID to load using the fetch routine */
    RecordID?: string;
    /** Open as a subdraft */
    chain?: boolean;
    /** Load existing draft */
    loadDraftID?: string;
    /** load the specified loadDraftID; do not search for existing subdrafts */
    omitChilds?: boolean;
    /** @internal */
    returnData?: boolean | ExitState;
    extras?: NavigationExtras;
    /** Open the draft as a modal. Only one step is supported in modal drafts. */
    openAsModal?: boolean;
    /** when loading the draft as a modal from archive, navigate to this URL first in the primary outlet */
    modalParentURL?: string[];
    /** show all active steps (default: modal - false, nonmodal - true) */
    showAllSteps?: boolean;
    /** URL to return on cancel */
    cancelURL?: string[];
    /* Create a copy instead of editing */
    copy?: boolean;
    /* disable save button */
    readonly?: boolean;
    /* lock IDs */
    resourceIDs?: string[];
}

export interface IDraftInfo {
    id: string;
    title: string;
    link: string[];
    active: boolean;
    icon?: string;
    modified: DateTime;
    created: DateTime;
    category?: string;
}
