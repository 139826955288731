import { FeatureFlags, FrontendPermissions } from "@vierkant-software/types__api";
import { TreeNode } from "primeng/api";
import { environment } from "src/environments/environment";

export interface gcTreeNode extends TreeNode {
    extraLabel?: "beta" | "newFeature";
    children?: gcTreeNode[];
    permissions?: FrontendPermissions[];
    feature?: FeatureFlags;
    //'yyyy-mm-dd' |true/false
    isBeta?: string | boolean;
    //'yyyy-mm-dd' |true/false
    isNew?: string | boolean;
}

export const menuItems: gcTreeNode[] = [
    {
        icon:  'home',
        label: 'Dashboard',
        data:  ['/'],
    },
    {
        icon:     'sitemap',
        label:    'Organisation',
        children: [
            {
                label:       'Abwesenheit',
                data:        ['/', 'calendar','absence'],
                permissions: [FrontendPermissions.absenceOverview_view],
            },
            {
                label:       'Organigramm',
                extraLabel:  "beta",
                data:        ['/', 'org-chart', 'overview'],
                permissions: [FrontendPermissions.orgCharts_view],
            },
            {
                label:       'Projekte',
                data:        ['/', 'projects-overview'],
                permissions: [FrontendPermissions.projects_view],
            },
            {
                label:       'Schichtplanung',
                extraLabel:  "beta",
                data:        ['/', 'calendar', 'shifts'],
                permissions: [
                    FrontendPermissions.shiftPlaning_view,
                    FrontendPermissions.shiftPlaning_edit,
                    FrontendPermissions.shiftPlan_import,
                    FrontendPermissions.shiftPlan_export
                ],
                feature: FeatureFlags.shift_planning
            },
        ]
    },
    {
        icon:     'play',
        label:    'Check-in',
        children: [
            {
                label:       'Anwesenheit',
                data:        ['/', 'attendance-overview'],
                permissions: [FrontendPermissions.attendanceList_view],
            },
            {
                label:       'Zeitkorrektur&shy;anträge',
                data:        ['/', 'time-corrections'],
                permissions: [FrontendPermissions.timeCorrections_edit],
            },
        ]
    },
    {
        icon:     'chart-simple',
        label:    'Auswertungen',
        children: [
            {
                label:       'Abwesenheiten',
                data:        ['/', 'absence-selection'],
                permissions: [FrontendPermissions.absenceStats_view],
                extraLabel:  "beta",
            },
            {
                label:       'Stundensätze',
                data:        ['/', 'salaries'],
                // TODO change permission
                permissions: [FrontendPermissions.salaryStats_View],
                extraLabel:  "beta",
            },
            {
                label:       'Arbeits&shy;zeiten',
                data:        ['/', 'analyses', 'working-time'],
                permissions: [FrontendPermissions.workTimeStats_view],
            },
            {
                label:       'Projekte',
                data:        ['/', 'analyses', 'projects'],
                permissions: [FrontendPermissions.projectStats_view],
            }
        ]
    },
    {
        icon:     'user',
        label:    'Personen',
        children: [
            {
                label:       'Neuer Mitarbeiter',
                data:        ['%', 'contract-employee', 'contract-selection'],
                permissions: [FrontendPermissions.employeeContracts_edit],
            },
            {
                label:       'Personen&shy;suche',
                data:        ['/', 'person'],
                permissions: [FrontendPermissions.personSearch_view],
            },
        ]
    },
    {
        icon:     'gear',
        label:    'Einstellungen',
        children: [
            {
                label:    'Abwesenheiten',
                children: [
                    {
                        label:       'Abwesenheits&shy;typen',
                        data:        ['/', 'absence-types'],
                        permissions: [FrontendPermissions.absenceTypes_view],
                    },
                    {
                        label:       'Übertrag Guthaben',
                        data:        ['/', 'absence-credit-transfer'],
                        permissions: [FrontendPermissions.absenceAccountTransfer_edit],
                    },
                    {
                        label:       'Verfall Guthaben',
                        data:        ['/', 'absence-credit-expiration'],
                        permissions: [FrontendPermissions.absenceAccountExpiry_edit],
                    }
                ]
            },
            {
                label:       'Ansicht',
                data:        ['%', 'company-settings', 'view'],
                permissions: [FrontendPermissions.viewSettings_edit],
            },
            {
                label:       'Berechtigungsgruppen',
                data:        ['/', 'permission-groups'],
                permissions: [FrontendPermissions.permissionGroups_view],
            },
            {
                label:       'DATEV',
                data:        ['/', 'datev'],
                isNew:       '2024-05-19',
                permissions: [FrontendPermissions.datev_edit,FrontendPermissions.datev_hr_eAU],
                feature:     FeatureFlags.datev,
            },
            {
                label:       'Dokumente',
                data:        ['/', 'directory-overview'],
                isNew:       '2024-01-31',
                permissions: [FrontendPermissions.documents_view],
            },
            {
                label:       'Feiertage',
                data:        ['/', 'calendar', "settings", "exceptions"],
                permissions: [FrontendPermissions.holidays_view],
            },
            {
                label:    'Firmendaten',
                children: [
                    {
                        label:       'Einstellungen',
                        data:        ['%', 'studio-settings', 'company', 'settings'],
                        permissions: [FrontendPermissions.companySettings_edit],
                    },
                    {
                        label:       'Geschäftszeiten',
                        data:        ['/', 'calendar', "settings-opening-times"],
                        extraLabel:  "newFeature",
                        permissions: [FrontendPermissions.openingTimes_edit],
                    }
                ]
            },
            {
                label:    'MA-Verträge',
                children: [
                    {
                        label:       'Vertrags&shy;vorlagen',
                        data:        ['/', 'employee-contract-template-overview'],
                        permissions: [FrontendPermissions.employeeContractTemplates_view],
                    },
                    {
                        label:       'Zeit&shy;profile',
                        data:        ['/', 'time-tracking'],
                        permissions: [FrontendPermissions.timeTrackingSets_view],
                    },
                    {
                        label:       'Zuschläge',
                        data:        ['/', 'surcharge-overview'],
                        permissions: [FrontendPermissions.surcharge_view],
                    }
                ]
            },
            {
                label:       'Terminals',
                data:        ['/', 'terminals-overview'],
                permissions: [FrontendPermissions.terminals_view],
            },
            // {
            //     label:       'Terminvorlagen',
            //     data:        ['/', 'calendar', 'settings', 'event-templates'],
            //     permissions: [],
            // },
        ]
    },
    {
        icon:     'screwdriver-wrench',
        label:    'ClusterAdmin',
        children: [
            {
                label:       'Import Users',
                data:        ['/', 'import', 'users'],
                permissions: [FrontendPermissions.ClusterAdmin],
            },
        ]
    },
];

if (!environment.production) {
    menuItems.push(
        {
            icon:     'screwdriver-wrench',
            label:    'Development',
            children: [
                {
                    label:       'Documentation',
                    permissions: [],
                    children:    [
                        {
                            label: 'Usage & Styling',
                            data:  ['/', 'demo', 'documentation', 'usage-styling'],
                        },
                        {
                            label: 'Components',
                            data:  ['/', 'demo', 'documentation', 'components'],
                        },
                    ]
                },
                {
                    label:       'Personensuche',
                    data:        ['/', 'demo', 'search'],
                    permissions: [],
                },
                {
                    label:    'Text',
                    data:     ['/','demo','text'],
                    children: [],
                },
                {
                    label:       'Maintenance',
                    data:        ['/','demo','maintenance'],
                    permissions: [],
                },
                {
                    label:    'Test',
                    data:     ['/','demo','test'],
                    children: [],
                },
                {
                    label:       'Kalender',
                    data:        ['/', 'calendar', 'appointments'],
                    permissions: [],
                    feature:     FeatureFlags.calendar,
                },
                {
                    label:       'Terminvorlagen',
                    data:        ['/', 'calendar', 'settings', 'event-templates'],
                    permissions: [],
                    feature:     FeatureFlags.calendar,
                },
            ]
        },
    );
}
